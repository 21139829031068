<template>
    <div>
        <!-- 头部 -->
        <Header></Header>
        <header-image :title="headerTitle"></header-image>
        <!-- /头部 -->

        <!-- 体部 -->
        <div  class="content_pay">
<!--            <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign" :rules="rules">-->
                <el-form :model="formLabelAlign" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

                <el-form-item label="用户名" prop="name">
                    <el-input  v-model="formLabelAlign.name" placeholder="请输入用户名信息" style="width:30%"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="region">
                    <el-input v-model="formLabelAlign.region" placeholder="请输入用户密码信息" style="width:30%" autocomplete="off" type="password"></el-input>
                </el-form-item>
                <el-form-item label="账户" prop="usr">
                    <el-input v-model="formLabelAlign.usr" placeholder="请输入账户信息" style="width:30%"></el-input>
                </el-form-item>
                <el-form-item label="确认账户" prop="usrA">
                    <el-input v-model="formLabelAlign.usrA" placeholder="请输入确认账户信息" style="width:30%"></el-input>
                </el-form-item>
                <el-form-item label="充值金额">
                    <el-radio-group v-model="formLabelAlign.resource">
                        <el-radio label="10元"></el-radio>
                        <el-radio label="50元"></el-radio>
                        <el-radio label="100元"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="网上银行" class="el-radio">
                        <el-radio label="1" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/zhaohang.gif" alt="" style="margin-bottom: -10px">
                        </el-radio>
                        <el-radio label="2" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/beijing.gif" alt="" style="margin-bottom: -10px">
                        </el-radio>
                        <el-radio label="3" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/buohai.gif" alt="" style="margin-bottom: -10px">
                        </el-radio>
                        <el-radio label="4" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/dongya.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <br/>
                        <el-radio label="5" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/gongshang.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="6" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/guangda.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="7" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/guangfa.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="8" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/jianshe.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <br/>
                        <el-radio label="9" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/jiaotong.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="10" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/minsheng.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="11" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/nanjing.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="12" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/ningbo.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <br/>
                        <el-radio label="13" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/nongcunshangye.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="14" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/nongye.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="15" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/pingan.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="16" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/shanghaibank.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <br/>
                        <el-radio label="17" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/shangpufa.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="18" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/shenfa.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="19" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/xingye.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="20" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/youzheng.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <br/>
                        <el-radio label="22" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/zheshang.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="23" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/zhongguo.gif" alt="" style="margin-bottom: -10px">

                        </el-radio>
                        <el-radio label="24" v-model="formLabelAlign.type" >
                            <img src="@/assets/xyhd/zhongxin.gif" alt="" style="margin-bottom: -10px;">
                        </el-radio>
                </el-form-item>
            </el-form>
        </div>
        <el-button type="primary" round @click="submitForm('ruleForm')" class="button-in">提交</el-button>

        <!-- /体部 -->

        <!-- 尾部 -->
        <Footer></Footer>
        <!-- /尾部 -->

    </div>
</template>

<script>
    export default {
        name: "Index",
        data(){
            return{
                headerTitle:"支付",
                labelPosition: 'right',
                formLabelAlign: {
                    name: '',
                    region: '',
                    usr: '',
                    usrA: '',
                    resource:"10元",
                    type:"1"
                },
                rules: {
                    name: [
                        { required: true, message: '请输入用户名信息', trigger: 'blur' },
                    ],
                    region: [
                        { required: true, message: '请选输入用户密码信息', trigger: 'change' }
                    ],
                    usr: [
                        { required: true, message: '请输入账户信息', trigger: 'blur' },
                    ],
                    usrA: [
                        { required: true, message: '请输入确认账户信息', trigger: 'change' }
                    ],
                }
            }
        },
        methods:{
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$message.warning("请前往网上银行页面进行充值！")
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style lang="scss">
    .content_pay {
        max-width: 1160px;
        min-width: 960px;
        min-height: 520px;
        padding: 0 0 50px;
        margin: 20px auto;
    }
    .el-radio{
        float: left;
        margin-left: 0px;
        margin-top: 0px;
    }
    .button-in{
        display:table-cell;
        margin: 0 auto; text-align: center;
        width: 200px;
    }
</style>